import axios from "axios";
import store from '../store';

const requests = [];

const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:5200' : 'https://dev-comments.we2p.de'
});

const protectedUrls = [
    '/api/discussion/like'
];

// TODO: add notification
axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('venus__token');

    if (protectedUrls.find((url) => config.url.includes(url)) !== undefined) {
        const user = store.getters['auth/user'];

        if (user && !(user.isAuthenticated || token)) {
            store.dispatch('auth/redirect');
            return;
        }
    }

    if (token) {
        config.headers = {
            ...config.headers,
            'Authorization': 'Bearer ' + token
        }
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


axiosInstance.interceptors.response.use(response => {
    return response;
}, error => {
    const response = error.response;

    if (error && response && response.status === 401 && protectedUrls.find((url) => url.includes(response.config.url)) !== undefined) {
        store.dispatch('auth/redirect');
    } else if (response && (response.status === 500 || response.status === 502)) {
        // TODO: add error notification
    }

    return Promise.reject(error);
});


//axiosInstance, axios
export default {
    get(name, path) {
        this.cancel(name);

        const request = axios.CancelToken.source();
        requests.push({name: name, request: request});
        return axiosInstance.get(path, {cancelToken: request.token});
    },
    put(name, path, data = {}) {
        this.cancel(name);

        const request = axios.CancelToken.source();
        requests.push({name: name, request: request});
        return axiosInstance.put(path, data, {cancelToken: request.token});
    },
    post(name, path, data = {}) {
        this.cancel(name);

        const request = axios.CancelToken.source();
        requests.push({name: name, request: request});
        return axiosInstance.post(path, data, {cancelToken: request.token});
    },
    cancel(name) {
        for (const entry of requests.map((value, index) => ({index, value}))) {
            if (entry.value.name === name) {
                entry.value.request.cancel();
                requests.splice(entry.index, 1);
            }
        }
    }
}
