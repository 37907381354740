
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { cart, person, list, home, hammer } from 'ionicons/icons';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

export default {
    name: 'Tabs',
    components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
    setup() {
        return { cart, person, list, home, hammer }
    },
    methods: {
        afterTabChange() {
            Haptics.impact({ style: ImpactStyle.Medium });
            console.log("changed");
        }
    }
}
