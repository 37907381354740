
import {IonApp, IonRouterOutlet} from '@ionic/vue';
import {defineComponent} from 'vue';
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  async created(): Promise<void> {
    // try to authenticate user with already existing token pair
    this.authenticate().then((authenticated: boolean): void => {
      if (authenticated)
        return;

      this.redirect();
    });
  },
  methods: {
    ...mapActions({authenticate: 'auth/authenticate', redirect: 'auth/redirect'})
  }
});
