import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import Tabs from '../views/Tabs.vue'
import store from "@/store";
import {Storage} from '@capacitor/storage';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/tabs/home'
    },
    {
        path: '/tabs/',
        component: Tabs,
        children: [
            {
                path: '',
                redirect: '/tabs/home'
            },
            // home
            {
                path: 'home',
                component: () => import('@/views/Home/Home.vue')
            },
            // recipes
            {
                path: 'recipes',
                component: () => import('@/views/Recipes/Recipes.vue'),
            },
            {
                path: 'recipes/:recipeID',
                component: () => import('@/views/Recipes/RecipeDetail.vue'),
            },
            // tools
            {
                path: 'shoppinglist',
                component: () => import('@/views/ShoppingList/ShoppingList.vue')
            },
            // tools
            {
                path: 'tools',
                component: () => import('@/views/Tools/Tools.vue')
            },
            {
                path: 'tools/hefekugelrechner',
                component: () => import('@/views/Tools/YeastBallCalculator.vue')
            },
            {
                path: 'tools/ausrollrechner',
                component: () => import('@/views/Tools/RollOutDimensionCalculator.vue')
            },
            // profile
            {
                path: 'profile',
                component: () => import('@/views/Profile/Profile.vue')
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (route) => {
    if (route.path.includes('callback')) {
        const token = route.query.token;
        const refresh_token = route.query.refresh_token;

        console.log(token);

        await Storage.set({key: 'venus__token', value: `${token}`});
        await Storage.set({key: 'venus__refresh_token', value: `${refresh_token}`})

        store.dispatch('auth/authenticate');

        // normalize URL
        const segments = location.href.split('/');
        const normalized = [];

        for (const segment of segments) {
            if (!segment.includes('callback')) {
                normalized.push(segment);
            }
        }

        location.href = normalized.join('/');
    }
});

export default router
