import {Storage} from '@capacitor/storage';
import axios from "@/utility/axios";

const store = {
    state: {
        user: {
            id: undefined,
            username: undefined,
            email: undefined,
            authenticated: false,
        },
        token: undefined,
        refresh_token: undefined,
        redirecting: false,

        config: {
            clientId: '256',
            clientType: 'device'
        }
    },
    getters: {
        user: state => state.user,
        isRedirecting: state => state.redirecting
    },
    actions: {
        async authenticate({commit, state}) {
            state.token = (await Storage.get({
                key: "venus__token"
            })).value;

            state.refresh_token = (await Storage.get({
                key: "venus__refresh_token"
            })).value;

            return new Promise((resolve) => {
                // check if auth cookie isset
                if (!(state.token && state.refresh_token)) {
                    resolve(false);
                    return;
                }

                // try to authenticate
                resolve(axios.post('auth/authenticate', '/oauth/authenticate', {
                    token: state.token
                }).then((res) => {
                    // authenticated
                    if (res.status === 200) {
                        const data = res.data.data;

                        commit('setUser', {
                            id: data.user.id,
                            username: data.user.username,
                            email: data.user.email,
                            authenticated: true
                        });

                        return true;
                    }

                    return false;
                }).catch((err) => {
                    return false;
                }));
            });
        },
        redirect({commit, state, rootGetters}) {
            if (state.isRedirecting)
                return;

            commit('auth/setRedirecting', true);

            // redirect to SSO page
            const url = new URL(process.env.NODE_ENV === 'development' ? 'http://localhost:5200/oauth/login' : 'https://dev-comments.we2p.de/oauth/login');

            // remove / from last pos
            let redirect_uri = location.href;
            const lastChar = location.href.charAt(location.href.length - 1);

            if (lastChar === '/') {
                redirect_uri = `${redirect_uri}callback`
            } else {
                redirect_uri = `${redirect_uri}/callback`
            }

            url.searchParams.set('client_id', state.config.clientId);
            url.searchParams.set('client_type', state.config.clientType);
            url.searchParams.set('redirect_uri', redirect_uri);

            window.location.href = url.href;
        },
        setUser({commit}, user) {
            commit('setUser', user)
        }
    },
    mutations: {
        setUser: ((state, user) => {
            state.user = user;
        }),
        setRedirecting: ((state, redirecting) => {
            state.redirecting = redirecting;
        }),
        logout: ((state) => {
            state.token = undefined;
            state.refresh_token = undefined;

            Storage.remove({key: 'venus__token'});
            Storage.remove({key: 'venus__refresh_token'});
        })
    }
}

export default {
    ...store,
    namespaced: true
}
